import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence } from "framer-motion";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import { generalcleaningkitchenSEO } from "../components/data/seo";
import {
  container,
  preview,
  stock,
  option,
  price,
  list,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";

function Generalcleaningkitchen() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={generalcleaningkitchenSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={container}>
          <div className={preview}>
            <h2 className={title}>Генеральная уборка кухни</h2>
            <p className={description}>от 3000₽</p>
            <StaticImage
              src="../images/services/5.jpg"
              alt="обложка для Генеральной уборки кухни"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>Закажите клининг по акции!</h3>
            <p>
              При заказе любого типа помещения - скидка до 30% на услуги
              химчистки мягкой мебели
            </p>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать уборку <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={option}
            style={{
              background: "linear-gradient(180deg, #242886 0%, #197BBD 100%)",
            }}
          >
            <h2 className={title}>Что входит:</h2>
            <p className={description}>
              Дополнительные позиции обсуждаются индивидуально
            </p>
            <p className={list}>
              - Мытье натяжного потолка, обеспыливание стен
              <br />
              - Мытье моющихся поверхностей стен и удаление жира
              <br />
              - Мытье осветительных приборов
              <br />
              - Мытье вытяжки
              <br />
              - Мытье кухонного гарнитура (внутри, снаружи; отмываем пыль, грязь
              и жир)
              <br />
              - Мытье холодильника, духового шкафа, СВЧ (снаружи)
              <br />
              - Мытье дверей и дверных проемов
              <br />- Мытье посуды
            </p>
          </div>
          <div
            className={price}
            style={{
              background:
                "linear-gradient(3.2deg, #1D00A8 -22.42%, #197BBD 100%)",
            }}
          >
            <h2 className={title}>Прайс</h2>
            <p className={description}>
              Для расчета более точной цены, оставьте заявку
            </p>
            <p className={list}>
              6-12 кв.м / от 3000₽
              <br />
              13 - 16 кв.м / от 4100₽
              <br />
              17 0 22 кв.м / от 5000₽
              <br />
              от 23 кв.м / от 6000₽
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Прочие услуги:</h3>
        <Otherservice hide={4} />
        <AnimatePresence>
          {openForm && (
            <Form
              service="генеральную уборку кухни"
              closeForm={() => setOpenForm(false)}
            />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Generalcleaningkitchen;
